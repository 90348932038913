import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IRoute } from '../../interface/route';


@Injectable({
  providedIn: 'root'
})
export class RouteService {

  url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootRoute;
  }


  getRoutes(filter: any, pageNumber: number, pageSize: number): Observable<any> {
    let parameters = '';

    if (filter) {
      if (filter.sort) {
        parameters += !parameters.length ? `?sort=${filter.sort}` : `&sort=${filter.sort}`;
      }

      if (filter.createdBy) {
        parameters += !parameters.length ? `?createdBy=${filter.createdBy}` : `&createdBy=${filter.createdBy}`;
      }

      if (filter.geographicArea) {
        parameters += !parameters.length ? `?geographicArea=${filter.geographicArea}` : `&geographicArea=${filter.geographicArea}`;
      }

      if (filter.avgDurationMin) {
        parameters += !parameters.length ? `?avgDurationMin=${filter.avgDurationMin}` : `&avgDurationMin=${filter.avgDurationMin}`;
      }

      if (filter.avgDurationMax) {
        parameters += !parameters.length ? `?avgDurationMax=${filter.avgDurationMax}` : `&avgDurationMax=${filter.avgDurationMax}`;
      }

      if (filter.startLat) {
        parameters += !parameters.length ? `?startLat=${filter.startLat}` : `&startLat=${filter.startLat}`;
      }

      if (filter.startLon) {
        parameters += !parameters.length ? `?startLon=${filter.startLon}` : `&startLon=${filter.startLon}`;
      }

      if (filter.startMaxSearchRadius) {
        parameters += !parameters.length ? `?startMaxSearchRadius=${filter.startMaxSearchRadius}` : `&startLat=${filter.startMaxSearchRadius}`;
      }

      if (filter.endLat) {
        parameters += !parameters.length ? `?endLat=${filter.endLat}` : `&endLat=${filter.endLat}`;
      }

      if (filter.endLon) {
        parameters += !parameters.length ? `?endLon=${filter.endLon}` : `&endLat=${filter.endLon}`;
      }

      if (filter.endMaxSearchRadius) {
        parameters += !parameters.length ? `?endMaxSearchRadius=${filter.endMaxSearchRadius}` : `&endMaxSearchRadius=${filter.endMaxSearchRadius}`;
      }

      if (filter.routemode) {
        parameters += !parameters.length ? `?routemode=${filter.routemode}` : `&routemode=${filter.routemode}`;
      }

      if (filter.categories.length) {
        filter.categories.forEach(category => {
          parameters += !parameters.length ? `?categories=${category.name}` : `&categories=${category.name}`;
        });
      }

      if (filter.typology.length) {
        filter.typology.forEach(typology => {
          parameters += !parameters.length ? `?typology=${typology.name}` : `&typology=${typology.name}`;
        });
      }

      if (filter.biketypes.length) {
        filter.biketypes.forEach(type => {
          parameters += !parameters.length ? `?biketypes=${type}` : `&biketypes=${type}`;
        });
      }

      if (filter.difficulty.length) {
        filter.difficulty.forEach(type => {
          parameters += !parameters.length ? `?difficulty=${type.value}` : `&difficulty=${type.value}`;
        });
      }

      parameters += !parameters.length ? `?pageNumber=${pageNumber}&pageSize${pageSize}` : `&pageNumber=${pageNumber}&pageSize${pageSize}`;
    } else {
      parameters = '?pageNumber=' + pageNumber + '&pageSize=' + pageSize;
    }

    return this.httpClient.get(this.url + 'routes' + parameters);
  }

  getRouteDetails(idRoute: string): Observable<IRoute> {
    const parameters = '?IdRoute=' + idRoute;
    return this.httpClient.get<IRoute>(this.url + 'routedetail' + parameters);
  }

  upsertRoute(route: any) {
    return this.httpClient.post(this.url + 'upsert', route);
  }

  softDeleteRoute(idRoute: any) {
    return this.httpClient.post(this.url + 'delete', { id: idRoute });
  }

  acceptRoute(id: any) {
    return this.httpClient.post(this.url + 'acceptroute', id);
  }
}
